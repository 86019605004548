var __jsx = React.createElement;
/** @format */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import moment from 'moment';
import LazyLoad from 'react-lazy-load';
import { OFFER_TYPES, REDEMPTION_TYPES, isOfferValid, pointsCalculator, merchantCardCallout } from "../../../constants/app";
import { getOfferContent, getRedemptionContent, isExpired, showDaysLeft } from "../offerUtils";
import { Icon, CardCallout } from "../../_global";
import CardButton from "../../Favourites/CardButton";
import { slugify } from "../../../utils/slugify";
var tip = "/static/offers/ico_time.svg";
var warn = "/static/offers/error-icon.svg";
import { offerViewed, offerRequested as offerRequestedReducer } from "../../../reducers/markAs";
import CardDetail from "./CardDetails";
import { cardProps, cardPropsDefaults } from "./offerProps";
import { CardContainer, CardBody, CardCta, CardFooter, CardInfo, MerchantLogo, OfferImage, CtaButton, InfoHeader, InfoHeading, InfoBody, InfoSubHeading, InfoSpecial, InfoButton } from "./style";
import { getVisaAmexEnrolmentFlag } from "../../../lib/optimizelyFlagUtils";
var WithVisibility = connect(null, {
  viewed: offerViewed
})(function (_ref) {
  var needsVisibilityTracking = _ref.needsVisibilityTracking,
    offerId = _ref.offerId,
    children = _ref.children,
    viewed = _ref.viewed;
  if (needsVisibilityTracking && process.browser) {
    /* eslint-disable */
    var VisibilitySensor = require('react-visibility-sensor').default;
    /* eslint-enable */
    var visibleCallback = function visibleCallback(isVisible) {
      if (isVisible) {
        viewed(offerId);
      }
    };
    return __jsx(VisibilitySensor, {
      onChange: visibleCallback
    }, children);
  }
  return children;
});
var OfferCard = function OfferCard(_ref2) {
  var callout = _ref2.callout,
    offerIdMls = _ref2.offerIdMls,
    pointsEarn = _ref2.pointsEarn,
    dollarSpend = _ref2.dollarSpend,
    onClick = _ref2.onClick,
    offerType = _ref2.offerType,
    offerEndDate = _ref2.offerEndDate,
    offerStartDate = _ref2.offerStartDate,
    importantInfo = _ref2.importantInfo,
    offerImg = _ref2.offerImg,
    merchantLogo = _ref2.merchantLogo,
    loading = _ref2.loading,
    prevPoints = _ref2.prevPoints,
    newStore = _ref2.newStore,
    wideView = _ref2.wideView,
    ctaStatus = _ref2.ctaStatus,
    shopNow = _ref2.shopNow,
    findStore = _ref2.findStore,
    disabledMallOffer = _ref2.disabledMallOffer,
    discountType = _ref2.discountType,
    conversionRate = _ref2.conversionRate,
    specialTerms = _ref2.specialTerms,
    lazyLoad = _ref2.lazyLoad,
    hasError = _ref2.hasError,
    merchantName = _ref2.merchantName,
    merchantId = _ref2.merchantId,
    offer = _ref2.offer,
    hasName = _ref2.hasName,
    bonusRibbons = _ref2.bonusRibbons,
    noShadow = _ref2.noShadow,
    offerRequested = _ref2.offerRequested,
    ctaCopy = _ref2.ctaCopy,
    redemptionType = _ref2.redemptionType;
  var _useState = useState(false),
    showImportantInfo = _useState[0],
    setShowImportantInfo = _useState[1];
  var _useState2 = useState(false),
    isLoading = _useState2[0],
    setIsLoading = _useState2[1];
  var _useState3 = useState(false),
    isError = _useState3[0],
    setIsError = _useState3[1];
  var needsVisibilityTracking = OFFER_TYPES.MCO === offerType || OFFER_TYPES.NURSERY === offerType;
  useEffect(function () {
    if (needsVisibilityTracking) {
      offerRequested(offerIdMls);
    }
  }, []);
  var getCopyForMainCTAButton = function getCopyForMainCTAButton() {
    if (disabledMallOffer) {
      return 'Try again later';
    }
    if (expiredOffer) {
      return "Expired ".concat(moment(offerEndDate).format('Do MMM YYYY'));
    }
    if (isError || hasError) {
      return 'please try again';
    }
    return ctaCopy || ctaText;
  };
  var handleActivate = function handleActivate() {
    setIsLoading(true);
    setIsError(false);
    // Sometimes onClick is a promise, other times it isn't, Promise.resolve handles
    // both nicely
    Promise.resolve(onClick()).then(function () {
      setIsLoading(false);
      setIsError(false);
    }).catch(function () {
      setIsLoading(false);
      setIsError(true);
    });
  };
  var toggleImportantInfo = function toggleImportantInfo() {
    setShowImportantInfo(function (prevState) {
      return !prevState;
    });
  };
  var isVisaAmexEnabled = getVisaAmexEnrolmentFlag();
  if (!redemptionType && offerType === OFFER_TYPES.MALL) {
    redemptionType = REDEMPTION_TYPES.ONLINE;
  }
  var _getRedemptionContent = getRedemptionContent(redemptionType, offerType, isVisaAmexEnabled),
    redemptionText = _getRedemptionContent.text,
    redemptionImg = _getRedemptionContent.img;
  var _getOfferContent = getOfferContent(offerType),
    ctaText = _getOfferContent.ctaText;
  var expiredOffer = isExpired(offerType, offerEndDate);
  var validOffer = isOfferValid(offerStartDate, offerEndDate);
  var CTACopy = getCopyForMainCTAButton();
  var daysLeft = showDaysLeft(offerType, offerEndDate, callout);
  var CalloutCopy = callout ? pointsCalculator(parseInt(pointsEarn, 10), parseInt(prevPoints, 10)) : merchantCardCallout(offer || {});
  return __jsx(WithVisibility, {
    needsVisibilityTracking: needsVisibilityTracking,
    offerId: offerIdMls
  }, __jsx(CardContainer, {
    offerType: offerType,
    wideView: wideView,
    noShadow: noShadow
  }, validOffer && CalloutCopy && __jsx(CardCallout, {
    bonusRibbons: bonusRibbons,
    callType: CalloutCopy.split(' ')[0]
  }, CalloutCopy), !callout && newStore && !wideView && __jsx(CardCallout, {
    bonusRibbons: bonusRibbons,
    callType: "NEW"
  }, "NEW STORE"), offerImg && lazyLoad ? __jsx(LazyLoad, {
    height: 145,
    offset: 1000
  }, __jsx(OfferImage, {
    offerImg: offerImg
  })) : offerImg && __jsx(OfferImage, {
    offerImg: offerImg
  }), __jsx(CardButton, {
    merchantId: merchantId,
    rounded: true,
    merchantName: merchantName
  }), __jsx(CardBody, null, merchantLogo && lazyLoad ? __jsx(LazyLoad, {
    height: 60,
    offset: 500
  }, __jsx(MerchantLogo, {
    merchantLogo: merchantLogo,
    offerType: offerType
  })) : merchantLogo && __jsx(MerchantLogo, {
    merchantLogo: merchantLogo,
    offerType: offerType
  }), __jsx(CardDetail, {
    offerType: offerType,
    prevPoints: prevPoints,
    pointsEarn: String(pointsEarn),
    dollarSpend: dollarSpend,
    redemptionType: redemptionType,
    redemptionText: redemptionText,
    redemptionImg: redemptionImg,
    discountType: discountType,
    offerEndDate: offerEndDate,
    conversionRate: conversionRate
  }), ' ', offerType === OFFER_TYPES.NURSERY ? __jsx(CardCta, {
    offerType: offerType
  }, __jsx(Link, {
    href: "/link-card"
  }, __jsx("a", null, __jsx(CtaButton, {
    primary: true,
    "data-attribute": slugify(ctaText)
  }, ctaText)))) : __jsx(CardCta, {
    offerType: offerType
  }, (onClick || expiredOffer) && __jsx(CtaButton, {
    block: true,
    primary: true,
    onPress: handleActivate,
    status: ctaStatus,
    loading: isLoading,
    offerType: offerType,
    disabled: disabledMallOffer || expiredOffer,
    isError: isError || hasError,
    merchant: merchantName,
    hasName: offerType === OFFER_TYPES.MALL && hasName,
    "data-attribute": slugify(ctaText)
  }, expiredOffer && __jsx("img", {
    src: tip,
    alt: "Offer expired"
  }), (isError || hasError) && __jsx("img", {
    src: warn,
    alt: "Error occurred"
  }), CTACopy), shopNow && !expiredOffer && __jsx(CtaButton, {
    primary: true,
    onPress: shopNow,
    status: ctaStatus,
    loading: loading,
    offerType: offerType,
    merchant: merchantName,
    hasName: hasName,
    "data-attribute": "shop-now",
    "data-brand": merchantId
  }, "shop now"), findStore && !expiredOffer && __jsx(CtaButton, {
    outline: true,
    onPress: findStore,
    offerType: offerType,
    hasName: hasName,
    "data-attribute": "find-store",
    "data-brand": merchantId
  }, "find store"))), __jsx(CardFooter, null, daysLeft && __jsx("span", null, daysLeft), __jsx(InfoButton, {
    alignCenter: daysLeft,
    onClick: toggleImportantInfo
  }, "Terms & conditions")), __jsx(CardInfo, {
    isVisible: showImportantInfo
  }, __jsx(InfoHeader, null, __jsx("button", {
    onClick: toggleImportantInfo,
    "aria-label": "Close info"
  }, __jsx(Icon, {
    iconType: "closeCross",
    size: "base"
  })), __jsx(InfoHeading, null, "Terms & conditions")), offerType === OFFER_TYPES.MALL && __jsx(InfoSubHeading, null, "Online Mall:"), specialTerms && __jsx(InfoSpecial, null, specialTerms), __jsx(InfoBody, null, importantInfo))));
};
OfferCard.defaultProps = cardPropsDefaults;
var mapDispatchToProps = {
  offerRequested: offerRequestedReducer
};
export default connect(null, mapDispatchToProps)(OfferCard);